/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { jsx } from "theme-ui"

import type { AmmunitionPageQuery } from "../../../graphql-types"

import CategoryParentSection from "components/CategoryParentSection"
import Layout from "components/Layout"
import PageHero from "components/PageHero"

interface AmmunitionPageProps extends PageProps {
  data: AmmunitionPageQuery
}

const AmmunitionPage = ({ data, ...props }: AmmunitionPageProps) => {
  return (
    <Layout seo={data.strapiAmmunitionParent?.seo} location={props.location}>
      <PageHero
        title={data.strapiAmmunitionParent?.hero_title}
        image={
          data.strapiAmmunitionParent?.hero_image?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        imageAlt={data.strapiAmmunitionParent?.hero_image?.alternativeText}
        content={data.strapiAmmunitionParent?.hero_content}
        buttonOneText="view all ammunition"
        buttonOneVariant="primary"
        buttonOneUrl="/ammunition/all"
        buttonTwoText="download our catalog"
        buttonTwoVariant="secondary"
        buttonTwoUrl={data.strapiGlobal?.catalog?.localFile?.url as string}
        hasLocatorCTA
      />

      {data.allStrapiCategory.edges.map((category, index) => (
        <CategoryParentSection
          key={index}
          title={category.node.name as string}
          bulletImage={
            category.node.bulletImage?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          bulletImageAlt="rimfire bullet"
          categoryImage={
            category.node.introImage?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          categoryImageAlt="rimfire category image"
          buttonText={`view ${category.node.name as string} ammunition`}
          buttonUrl={`/ammunition/${category.node.slug as string}`}
          categoryPageDescription="Our rimfire ammunition is manufactured to ensure optimal reliability, accuracy, and performance. It features Aguila Prime technology, which is engineered and tested to Olympic shooting standards."
          categoryListDescription={category.node.categoryPageIntro as string}
        />
      ))}
    </Layout>
  )
}

export default AmmunitionPage

export const query = graphql`
  query AmmunitionPage {
    allStrapiCategory(sort: { fields: order }) {
      edges {
        node {
          name
          slug
          categoryPageIntro
          bulletImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          introImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    strapiGlobal {
      catalog {
        localFile {
          url
        }
      }
    }
    strapiAmmunitionParent {
      hero_content
      hero_link_button {
        link_button_text
        link_button_url
      }
      hero_image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      hero_title
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
